import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/SEO'
import HeroOther from "../components/HeroOther"
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import Title from "../components/Title"
import SubTitle from "../components/SubTitle"
import Descriptions from "../components/Descriptions"
import AboveFooter from "../components/AboveFooter"

export const SsdlcIntroductionPageTemplate = ({
  seo,
  hero,
  contactus,
  aboutsection,
  tssdlcsection,
  scpsection,
  fixedimagesection
}) => (
  <div>
    <SEO title={seo.title} description={seo.description} />
    <HeroOther data={hero} />
    <section className="section">
      <div className="container">
        <div className="columns">
          <div className="column is-8">
            <SubTitle title={contactus.title} />
            <Descriptions descriptions={contactus.descriptions} margin="1rem 0" />
          </div>
          <div className="column is-1"></div>
          <div className="column is-3">
            <a href={contactus.buttonlink} >
              <button style={{fontSize: 20, width: "100%", background: "#53c5d1" }}>{contactus.buttontxt}</button>
            </a>
          </div>
        </div>
      </div>
    </section>
    <section className="section" style={{ background: `${aboutsection.sectionbg}`}}>
      <div className="container">
        <div style={{ marginTop: "2rem"}}>
          <Title title={aboutsection.title} color={aboutsection.textcolor} />
          <Title title={aboutsection.secondtitle} color={aboutsection.textcolor}/>
          <SubTitle title={aboutsection.subheader} color={aboutsection.textcolor} margin="1.5rem 0"/>
          <Descriptions descriptions={aboutsection.descriptions} color={aboutsection.textcolor} margin="1rem 0" />
        </div>
        <div className="columns">
          <div className="column is-9"></div>
          <div className="column is-3">
            <a href={aboutsection.buttonlink} >
              <button style={{fontSize: 20, width: "100%", background: "#53c5d1" }}>{aboutsection.buttontxt}</button>
            </a>
          </div>
        </div>
        <div className="columns">
          <div className="column is-6"></div>
          <div className="column is-6">
            <div style={{maxWidth: 300, height: 10}}>
              <PreviewCompatibleImage imageInfo={aboutsection.image} />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section" style={{ background: `${tssdlcsection.sectionbg}`}}>
      <div className="container">
        <div style={{ marginTop: "8rem"}}>
          <Title title={tssdlcsection.title} color={tssdlcsection.textcolor} />
          <Title title={tssdlcsection.secondtitle} color={tssdlcsection.textcolor} />
          <Descriptions descriptions={tssdlcsection.descriptions1} color={tssdlcsection.textcolor} margin="1rem 0" />
          <SubTitle title={tssdlcsection.subheader2} color={tssdlcsection.textcolor} margin="1.5rem 0"/>
          <Descriptions descriptions={tssdlcsection.descriptions2} color={tssdlcsection.textcolor} margin="1rem 0" />
          <div className="columns custom-columns">
            <div className="column is-4 mobile-center">
              <div style={{maxWidth: 300, height: 100}}>
                <PreviewCompatibleImage imageInfo={tssdlcsection.image} />
              </div>
            </div>
            <div className="column is-8">
              <Descriptions descriptions={tssdlcsection.sidedescriptions} color={tssdlcsection.textcolor} margin="1rem 0" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="section" style={{ margin: "5rem 0 2rem"}}>
      <div className="container">
        <div className="columns" >
          <div className="column is-8">
            <Title title={scpsection.title} />
            <SubTitle title={scpsection.subheader1}  margin="1.5rem 0"/>
            <Descriptions descriptions={scpsection.sidedescriptions} />
          </div>
          <div className="column is-4">
            <div style={{maxWidth: 300, margin: "auto"}}>
              <PreviewCompatibleImage imageInfo={scpsection.image} />
            </div>
          </div>
        </div>
        <Descriptions descriptions={scpsection.descriptions} />
        <SubTitle title={scpsection.subheader2}  margin="1.5rem 0 0"/>
      </div>
    </section>
    <AboveFooter data={fixedimagesection} height="300px" />
  </div>
)

SsdlcIntroductionPageTemplate.propTypes = {
  seo: PropTypes.object,
  hero: PropTypes.object,
  contactus: PropTypes.object,
  aboutsection: PropTypes.object, 
  tssdlcsection: PropTypes.object,
  scpsection: PropTypes.object,
  fixedimagesection: PropTypes.object
}

const SsdlcIntroductionPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <SsdlcIntroductionPageTemplate
        seo={frontmatter.seo}
        hero={frontmatter.hero}
        contactus={frontmatter.contactus}
        aboutsection={frontmatter.aboutsection}
        tssdlcsection={frontmatter.tssdlcsection}
        scpsection={frontmatter.scpsection}
        fixedimagesection={frontmatter.fixedimagesection}
      />
    </Layout>
  )
}

SsdlcIntroductionPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
}

export default SsdlcIntroductionPage

export const pageQuery = graphql`
  query SsdlcIntroductionPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "ssdlc-introduction" } }) {
      frontmatter {
        seo {
          title
          description
        }
        hero{
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          midimage{
            alt 
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          sideimage{
            alt
            align 
            image {
              childImageSharp {
                fluid(maxWidth: 260, quality: 100) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          description
          buttontxt
          buttonlink
        }
        contactus {
          title
          descriptions
          buttontxt
          buttonlink
        }
        aboutsection {
          sectionbg
          textcolor
          title
          secondtitle
          subheader
          descriptions
          buttontxt
          buttonlink
          image {
            childImageSharp {
              fluid(maxWidth: 400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tssdlcsection {
          sectionbg
          textcolor
          title
          subheader1
          descriptions1
          subheader2
          descriptions2
          image {
            childImageSharp {
              fluid(maxWidth: 260, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          sidedescriptions
        }
        scpsection {
          title
          subheader1
          descriptions
          sidedescriptions
          subheader2
          image {
            childImageSharp {
              fluid(maxWidth: 460, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        fixedimagesection {
          title
          secondtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
